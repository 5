import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlameraSharedService } from 'glamera-shared';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BillingService {
  private  companyId = 0;

  get apiUrl(): string {
    return `${environment.apiUrl}/subs/web/company/${this.companyId}/billings`;
  }

  get postUrl(): string {
    return `${environment.apiUrl}/subs/web/company/${this.companyId}/billings/payments`;
  }

  get paymentSuccessUrl(): string {
    return  `${environment.apiUrl}/subs/web/billings/payments/succeed`;
  }

  get paymentFailedUrl(): string {
    return  `${environment.apiUrl}/subs/web/billings/payments/failed`;
  }

  get token(): string {
    return  this.sharedService.token ??"";
  }




  constructor(private http: HttpClient ,
    private sharedService: GlameraSharedService
  ) {

    this.companyId=sharedService.company.id;
   }

  getBillings(criteria?:any): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token}`,
      'AppId': '7'
    });
     return this.http.get<any>(this.apiUrl, { headers , params:criteria}   );
  }

  getShowBillings(branchId: number): Observable<any> {
    const apiUrlShow = `${environment.apiUrl}/subs/web/company/${this.companyId}/billings?branchId=${branchId}`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token}`,
      'AppId': '7'
    });
    return this.http.get<any>(apiUrlShow, { headers });
  }

  postPayment(paymentData: any): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token}`,
      'AppId': '7'
    });

    return this.http.post<any>(this.postUrl, paymentData, { headers });
  }

  sendPaymentReference(paymentReference: string): Observable<any> {
    const headers = new HttpHeaders({
      'AppId': '7',
      'Content-Type': 'application/json',
      'lang': 'ar',
      'Authorization': `Bearer ${this.token}`
    });
    const body = {
      PaymentReference: paymentReference
    };
    return this.http.put(this.paymentSuccessUrl, body, { headers });
  }

  sendFailedPaymentReference(paymentReference: string): Observable<any> {
    const headers = new HttpHeaders({
      'AppId': '7',
      'Content-Type': 'application/json',
      'lang': 'ar',
      'Authorization': `Bearer ${this.token}`
    });
    const body = {
      PaymentReference: paymentReference
    };
    return this.http.put(this.paymentFailedUrl, body, { headers });
  }
}
