import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Branch, PaymentMethod } from '@s-app/common/models';
import { SideDialogService } from '@s-app/common/services';
import { BranchService } from '@bl-src/app/core/services/branch.service';
import { BillingService } from '@bl-src/app/core/services/billing.service';
import Swal from 'sweetalert2';
import { GlameraSharedService } from 'glamera-shared';
import { environment } from 'environments/environment';
import { GetSubscriptionByIdResponse } from '@s-app/common/models/company-subscription.model';

@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})
export class ShowBranchComponent implements OnInit {
  public branch: Branch;
  public errorMessage: string = '';
  public subscriptionId: number;
  private companyId = 0;
  public expandedInvoices: { [key: string]: boolean } = {};
  public paymentMethod: PaymentMethod;

  public invoices: any[] = [];
  public selectedInvoice: any | undefined;
  public showPaymentPopup: boolean = false;
  public selectedPaymentMethod: string = '';

  @ViewChild('invioceDetails') public invioceDetails!: ElementRef;

  public constructor(
    public translateService: TranslateService,
    private sideDialogService: SideDialogService,
    private branchService: BranchService,
    private billingService: BillingService,
    private route: ActivatedRoute,
    private sharedService: GlameraSharedService
  ) {

    this.companyId = sharedService.company.id;
  }

  public showEditMethodDialog(): void {
    this.sideDialogService.showEditMethodDialog(this.paymentMethod);
  }

  public showPayDialog(): void {
    this.sideDialogService.showPayDialog();
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.subscriptionId = +params['id'];
      this.branchService.getSubscription(this.subscriptionId).subscribe(
        (item: GetSubscriptionByIdResponse) => {
          console.log('Branches data:', item);
          if (item) {
            item
            this.loadShowInvoices(item.branch.branchId);

            if(item.branch.defaultPaymentMethod){
              this.paymentMethod = {
                HolderName: item.branch.defaultPaymentMethod.holderName,
                CardNumber: item.branch.defaultPaymentMethod.cardNumber ,
                ExpireYear: item.branch.defaultPaymentMethod.ExpiryYear ,
                ExpireMonth: item.branch.defaultPaymentMethod.ExpiryMonth ,
                email: "",
              }

            }

            this.branch = {
              branchId: item.branch.branchId,
              name: item.branch.branchName,
              billingIds: item.branch.billingIds,
              billingCostToPay: item.branch?.billingCostToPay,
              billingCostCurrency: item.branch?.billingCostCurrency,
              hasInvoice: item.branch.billingIds != null ? true : false,
              subscription: {
                subscriptionId: item.id,
                subscriptionPlanId: item.subscriptionPlanId,
                isTrial: item.isTrial,
                canSubscribe: item.canSubscribe,
                canUpgradeOrDowngrade: item.canUpgradeOrDowngrade,
                userCount: item.planUsersCount,
                planName: item.subscriptionPlanName,
                leftDays: item.daysLeft,
                maxUserCount: item.planUsersCount,
                cycle : item.cycle,
                ratio: item.ratio,
                price: {
                  amount: item.planPrice,
                  period: this.getPeriod(item.cycle),
                  periodInDays: item.cycle,
                  currency: item.planCurrency
                }
              },
            };



            console.log(this.branch);
          }
        },
        error => {
          console.log('Error:', error);
          this.errorMessage = error.message;
        }
      );
    });
  }

  loadBranchData(branchId: number): void {
    this.branchService.getSubscription(this.subscriptionId).subscribe(
      data => {
        console.log('Branches data:', data);
        if (data && data.result) {
          this.branch = data.result
            .map((item: any) => {
              this.loadShowInvoices(item.branch.accountSetupId);
              return {
                branchId: item.branch.accountSetupId,
                name: item.branch.providerName,
                subscription: {
                  planName: item.subsc,
                  leftDays: item.subscriptions[0].daysLeft,
                  userCount: item.subscriptions[0].planUsersCount,
                  maxUserCount: item.subscriptions[0].planUsersCount,
                  price: { amount: item.subscriptions[0].planPrice, period: 'month', periodInDayed: item.subscriptions[0].cycle },
                },
              };
            });
        }
      },
      error => {
        console.log('Error:', error);
        this.errorMessage = error.message;
      }
    );
  }

  loadShowInvoices(branchId: number): void {
    this.billingService.getShowBillings(branchId).subscribe(
      data => {
        this.invoices = data.result;
        console.log('Invoices:', this.invoices);
      },
      error => {
        console.error('Error fetching invoices:', error);
      }
    );
  }

  public showInvoice(code: string): void {
    this.selectedInvoice = this.invoices.find((invoice) => invoice.code === code);
    if (!this.selectedInvoice) {
      return;
    }
    Swal.fire({
      html: this.invioceDetails.nativeElement,
      showConfirmButton: false,
      showCloseButton: true,
      closeButtonHtml: '<i class="fa-solid fa-xmark text-xl text-gray-800"></i>',
      width: 'min(47rem, 100%)',
      customClass: {
        htmlContainer: 'm-0',
        closeButton: 'absolute left-0',
      },
    });
  }

  public sendPayment(): void {
    this.showPaymentPopup = true;
  }

  public selectPaymentMethod(method: string): void {
    this.selectedPaymentMethod = method;
  }


  public isPrimaryButton: any;
  public selectedBillingItem: any;
  public showPayment(invoice: any, billingItem: any, branchId: any, isPrimaryButton: boolean): void {
    console.log("object");
    this.selectedInvoice = invoice;
    this.selectedBillingItem = billingItem;
    this.isPrimaryButton = isPrimaryButton;
    this.showPaymentPopup = true;
  }

  public submitPaymentMethod(): void {
    if (!this.selectedPaymentMethod) {
      console.log('Please select a payment method.');
      return;
    }

    if (this.selectedInvoice && this.selectedBillingItem) {


      const paymentData = {
        BillingCode: this.selectedInvoice.code,
        BillingId: null,
        UserId: 13374,
        PaymentMethodEnum: this.selectedPaymentMethod === 'Mada' ? 2 : 0
      };

      this.billingService.postPayment(paymentData).subscribe(
        (response) => {
          console.log('Payment successful:', response);
          if (response.paymentProviderURL) {
            window.location.href = response.paymentProviderURL;
          }
          this.showPaymentPopup = false;
        },
        (error: any) => {
          console.error('Payment error:', error);
        }
      );
    }
  }




  public closePaymentPopup(): void {
    this.showPaymentPopup = false;
  }
  public toggleInvoiceDetails(invoiceCode: string): void {
    this.expandedInvoices[invoiceCode] = !this.expandedInvoices[invoiceCode];
  }



  public getPeriod(days: number): string {

    if (days === 1) {
      return this.translateService.instant('translate_common.day');
    } else if (days === 7) {
      return this.translateService.instant('translate_common.week');
    } else if (days === 30) {
      return this.translateService.instant('translate_common.month');

    }
    else if (days === 90) {
      return this.translateService.instant('translate_common.quarter');
    }
    else if (days === 180) {
      return this.translateService.instant('translate_common.halfYear');
    }
    else if (days === 360 || days === 365) {
      return this.translateService.instant('translate_common.year');
    } else {
      return this.translateService.instant('translate_common.days', { days });
    }

  }
}
