import { Component, OnInit } from '@angular/core';
import {   Addon } from '@s-app/common/models';
import { SideDialogService } from '@s-app/common/services';
import { BranchService } from '@bl-src/app/core/services/branch.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './branch-addons.component.html',
})
export class BranchAddonsComponent implements OnInit {
  public branchAddons: Addon[] = [];
  public errorMessage: string = '';
  Date: any;

  constructor(
    private SideDialogService: SideDialogService,
    private branchService: BranchService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const subscriptionId = Number(params.get('id'));
      if (subscriptionId) {
        this.branchService.getSubscription(subscriptionId).subscribe(
          (data:any) => {
            this.branchAddons =data.companyAddOns;
            console.log('Subscription Data:', this.branchAddons);
          },
          error => {
            this.errorMessage = 'Error loading subscription data';
            console.error(error);
          }
        );
      } else {
        this.errorMessage = 'Invalid subscription ID';
      }
    });
  }

  loadSubscriptions(subscriptionId: number): void {
    this.branchService.getSubscription(subscriptionId).subscribe(
      (data) => {
        this.branchAddons = data.companyAddOns;
        console.log('Subscription Data:', this.branchAddons);
      },
      (error) => {
        this.errorMessage = 'Error loading subscription data';
        console.error(error);
      }
    );
  }

  // transformData(data: Addon[]): Addon[] {
  //   return [
  //     {
  //       companyAddOnId: data.,
  //       name: data.subscriptionPlanName,
  //       description: 'Description of ' + data.subscriptionPlanName,
  //       subscription: {
  //         price: {
  //           amount: data.planPrice,
  //           period: 'Month',
  //           periodInDays: data.cycle,
  //           currency: data.addOnCurrency
  //         },
  //         leftDays: data.daysLeft,
  //       },
  //     },
  //   ];
  // }

  calculateLeftDays(endDate: string): number {
    const end = new Date(endDate);
    const now = new Date();
    return Math.max(
      0,
      Math.ceil((end.getTime() - now.getTime()) / (1000 * 60 * 60 * 24))
    );
  }


  public showPayDialog(): void {
    this.SideDialogService.showPayDialog();
  }

    calculateDateDifference(startDateString:   string, endDateString: string):number {
   var  startDate = new Date(startDateString);
   var endDate = new Date(endDateString);
    if (startDate && endDate) {
      const timeDiff = endDate.getTime() - startDate.getTime();
      return Math.ceil(timeDiff / (1000 * 3600 * 24));
    } else return 0;
  }


}
