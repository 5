import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { PlansService } from '@bl-src/app/core/services/plans.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormGridSpinnerComponent } from '@s-app/common/components/grid-spinner/form-grid-spinner.component';
import { SideDialogService } from '@s-app/common/services';
import { GlameraSharedService } from 'glamera-shared';
import { Subject } from 'rxjs';
import { Change } from './change.model';
import { BranchCacheService } from '@s-app/core/services/cache/branch-cache.service';
import { NotificationService } from '@bl-src/app/core/services/notification.service';

@Component({
  selector: 'app-buy-plan',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    FormGridSpinnerComponent,
  ],
  templateUrl: './buy-plan.component.html',
  styleUrl: './buy-plan.component.scss',
})
export class BuyPlanComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  constructor(
    private sideDialogService: SideDialogService,
    private sharedService: GlameraSharedService,
    private branchCacheService: BranchCacheService,
    private plansService: PlansService,
    private notiService: NotificationService
  ) {
    this.companyId = sharedService.company.id;
    this.initForm();
  }

  formGroup: FormGroup;
  isSubmitting: boolean = false;
  public selectedPaymentMethod: number = 2;
  public showPaymentPopup: boolean = false;

  change: Change;

  monthButton = document.getElementById('month') as HTMLInputElement;
  quarterButton = document.getElementById('quarter') as HTMLInputElement;
  yearButton = document.getElementById('year') as HTMLInputElement;

  @Input('branchId') public branchId: number | null = null;
  @Input('isUpgrade') public isUpgrade: boolean | null = null;
  @Input('closeCycle') public closeCycle: boolean | null = null;

  public companyId: number;

  plans: any[] = [];
  date: string;
  totalIncTax: number;
  selectedPlan: any;

  ngOnInit(): void {
    console.log('Buy plan OnInit');
    this.init();
  }

  init() {
    console.log('Buy plan component initialized');
    this.date = new Date().toDateString(); // Default date format
    this.getPlans();

    this.formGroup.valueChanges.subscribe((value) => {
      console.log('Form value changed:', value);
    });
  }

  ngAfterViewInit(): void {
    // Manually check the radio button if necessary
    this.prepareCycleForUpgrade();
  }

  onPlanChosen($event: any) {
    this.choosePlan($event.target.value);
  }

  choosePlan(planId: number): void {
      var plan = this.plans.filter((plan) => plan.planId == planId)[0];
      this.selectedPlan = plan;
  }

  removeCurrentPlan() {
    if (this.isUpgrade && this.planId?.value != null) {
      const planToRemove = this.plans.find((plan) => {
        return plan.planId == this.planId.value;
      });
      if (planToRemove) {
        this.plans = this.plans.filter(
          (plan) => plan.planId != planToRemove.planId
        );
        console.log('Plans:', this.plans);
        this.selectedPlan = this.plans[0];
        console.log('Selected plan:', this.selectedPlan);
        this.planId = this.selectedPlan.planId;
      }
    }
  }

  prepareCycleForUpgrade() {
    const month = document.getElementById('month') as HTMLInputElement;
    const quarter = document.getElementById('quarter') as HTMLInputElement;
    const year = document.getElementById('year') as HTMLInputElement;

    if (this.closeCycle) {
      month.disabled = true;
      quarter.disabled = true;
      year.disabled = true;
    }

    var cycle = this.formGroup.get('cycle')?.value;
    switch (cycle) {
      case 30:
        this.selectManualCycle(cycle, month);
        break;
      case 90:
        this.selectManualCycle(cycle, quarter);
        break;
      case 360:
        this.selectManualCycle(cycle, year);
        break;
      default:
        this.selectManualCycle(cycle, month);
        this.cycle = 30;
        break;
    }
  }
  selectManualCycle(cycle: number, radioButton: HTMLInputElement): void {
    radioButton.checked = true;
    if (this.closeCycle) {
      radioButton.disabled = false;
    }
  }

  trackByPlanId(index: number, plan: any): number {
    return plan.planId;
  }

  set planId(planId: any) {
    this.formGroup.get('planId')?.setValue(planId);
    this.choosePlan(planId);
  }

  get planId() {
    return this.formGroup.get('planId');
  }

  initForm(): void {
    this.formGroup = new FormGroup({
      planId: new FormControl(null, [Validators.required]),
      cycle: new FormControl(null, [Validators.required]),
    });
  }

  submit(): void {}

  public showPayDialog(): void {
    this.totalIncTax = this.selectedCyclePrice;
    this.sideDialogService.showPayDialog();
  }

  public hideDialog(): void {
    this.sideDialogService.showDialogSubject.next(false);
  }

  getPlans() {
    this.plansService.getPlans().subscribe(
      (data) => {
        this.plans = data.packageFeatures;

        if (this.plans.length > 0) {

          if (this.planId?.value) {
            this.removeCurrentPlan();
            this.choosePlan(this.planId.value);
          } else {

            this.planId = this.plans[0].planId;
            console.log('this Plan:', this.planId?.value);
            //this.formGroup.get('planId')?.setValue(this.plans[0].planId);
           // this.choosePlan(this.plans[0].planId);
          }
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  set cycle(cycle: any) {
    this.formGroup.get('cycle')?.setValue(cycle);
  }

  get cycle(): AbstractControl<number> | null {
    return this.formGroup.get('cycle');
  }

  get selectedCyclePrice() {
    try {
      return this.selectedPlan['cycle' + this.cycle?.value];
    } catch (ex) {
      return null;
    }
  }

  get cycleText() {

        if (this.cycle?.value== 30)
        return 'translate_monthly';

        else if (this.cycle?.value== 90)
        return 'translate_quarterly';

        else if (this.cycle?.value== 360)
        return 'translate_yearly';

        else
        return '';

  }

  selectOption(option: any): void {
    this.cycle?.setValue(option);
  }

  public closePaymentPopup(): void {
    this.showPaymentPopup = false;
  }
  public selectPaymentMethod(method: number): void {
    this.selectedPaymentMethod = method;
  }
  public upgrade(): void {
    if (this.selectedPaymentMethod == null) {
      alert('Please select payment method');
      return;
    }

    const subscriptionData = {
      paymentMethodEnum: this.selectedPaymentMethod,
      branchId: this.branchId,
      subscriptionPlanPriceId: this.selectedCyclePrice.planPriceId,
      cycle: this.cycle?.value,
      renewalOptions: 1,
    };
    console.log(subscriptionData);

    this.plansService.upgradeSubscription(subscriptionData).subscribe(
      (response) => {
        console.log('Payment successful:', response);
        if (response.paymentProviderURL && response.upgradeType == 2) {
          window.location.href = response.paymentProviderURL;
        } else if (response.upgradeType == 1) {
          this.showPaymentPopup = false;
          this.hideDialog();
          //   this.init();
        }
      },
      (error) => {
        console.error('Subscription error:', error);
      }
    );
  }

  public downgrade(): void {
    const subscriptionData = {
      branchId: this.branchId,
      subscriptionPlanPriceId: this.selectedCyclePrice.planPriceId,
      cycle: this.cycle?.value,
      renewalOptions: 1,
    };
    this.plansService.upgradeSubscription(subscriptionData).subscribe(
      (response) => {
        console.log('Payment successful:', response);
        if (response.paymentProviderURL && response.upgradeType == 2) {
          window.location.href = response.paymentProviderURL;
        } else if (response.upgradeType == 1) {
          this.showPaymentPopup = false;
          this.branchCacheService.refresh();
          this.hideDialog();
          //this.init();
        }
      },
      (error) => {
        console.error('Subscription error:', error);
      }
    );
  }

  public subscribeNewPlan(): void {
    if(this.selectedPaymentMethod == null){
      alert('Please select payment method');
      return;
    }
    const subscriptionData = {
      paymentMethodEnum: this.selectedPaymentMethod,
      branchId: this.branchId,
      subscriptionPlanPriceId: this.selectedCyclePrice.planPriceId,
      cycle: this.cycle?.value,
      renewalOptions: 1,
    };
    console.log(subscriptionData);
    this.plansService.subscribeToPlan(subscriptionData).subscribe(
      (response) => {
        console.log('Payment successful:', response);
        if (response.paymentProviderURL) {
          window.location.href = response.paymentProviderURL;
        }
        this.showPaymentPopup = false;
      },
      (error) => {

        this.notiService.notifyError(error.message, true);
      }
    );
  }

  subscribe(): void {
    if (this.change.isUpgrade == 0) {
      this.subscribeNewPlan();
    } else if (this.change.isUpgrade == 1) {
      this.downgrade();
    } else if (this.change.isUpgrade == 2) {
      this.upgrade();
    } else {
      console.error('Subscription error:', 'Invalid subscription type');
    }
  }

  public CheckSubscriptionChange() {
    var changeCheckModel = {
      branchId: this.branchId,
      subscriptionPlanPriceId: this.selectedCyclePrice.planPriceId,
      cycle: this.cycle?.value,
    };
    this.plansService.checkSubscriptionChange(changeCheckModel).subscribe(
      (response: Change) => {
        console.log('CheckSubscriptionChange response:', response);
        this.change = response;
        this.showPaymentPopup = true;
      },
      (error) => {
        console.error('CheckSubscriptionChange error:', error);
      }
    );
  }

  public showPayment(id?: number): void {
    debugger;
    if (this.branchId !== null) {
      this.showPaymentPopup = true;
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }
}
