import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private notificationSubject = new Subject<{
    message: string;
    isError: boolean;
  }>();

  public notification$ = this.notificationSubject.asObservable();

  public notifyError(message: string, isError: boolean): void {
    this.notificationSubject.next({ message: message, isError: isError });
  }
}
