import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlameraSharedService } from 'glamera-shared';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private sharedService: GlameraSharedService ,
    private cookieService: CookieService
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,

  ): Observable<HttpEvent<any>> {

    const token =   this.sharedService.token;
    if(!token){
      debugger;
      window.location.href = environment.saasURL + '/login';
    }
    const modifiedRequest = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });

    return next.handle(modifiedRequest);
  }
}
