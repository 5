import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { GlameraSharedService } from 'glamera-shared';
import { tap } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BranchService {
  private companyId = 0;
  public branchesCache: any = null;
  private branchListCache: any = null;
  private subscriptionsCache: { [key: number]: any } = {};

  get baseApiUrl():string{
    return `${environment.apiUrl}/subs/web/company/${this.companyId}/subscriptions`;
  }


  get baseGeneralBranches():string{
    return `${environment.apiUrl}/subs/web/generals/branches?companyId=${this.companyId}`;
  }

  constructor(private http: HttpClient ,
    private sharedService: GlameraSharedService
  ) {
    this.companyId=sharedService.company.id;
   }

  getBranchesSubscriptions(): Observable<any> {
    if (this.branchesCache) {
      return of(this.branchesCache);
    }

    const apiUrl = `${this.baseApiUrl}`;
    return this.http.get<any>(apiUrl).pipe(
      tap(data => this.branchesCache = data)
    );
  }

  getAllLiteBranches(): Observable<any> {

    if (this.branchListCache) {
      return of(this.branchListCache);
    }

    const apiUrl = `${this.baseGeneralBranches}`;
    return this.http.get<any>(apiUrl).pipe(
      tap(data => this.branchListCache = data)
    );
  }



  getSubscription(subscriptionId: number): Observable<any> {
    if (this.subscriptionsCache[subscriptionId]) {
      return of(this.subscriptionsCache[subscriptionId]);
    }

    const apiUrl = `${this.baseApiUrl}/${subscriptionId}`;
    return this.http.get<any>(apiUrl).pipe(
      tap(data => this.subscriptionsCache[subscriptionId] = data)
    );
  }
}
