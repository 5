<div class="fixed z-10 top-0 w-full h-20 shadow bg-white">
  <div class="flex h-full align-items-center px-20">
    <img src="assets/img/logo-text.svg" class="h-8" />
    <div class="flex-1 ltr:text-right rtl:text-left [&_a]:mx-6 [&_a]:text-decoration-none [&_a]:text-sm">
      <a routerLink='/subscription' routerLinkActive="text-primary-500 font-bold"
        [routerLinkActiveOptions]="{exact: false}">{{ 'translate_subscription' | translate }}</a>
      <a [routerLink]="['', 'plans']" routerLinkActive="text-primary-500 font-bold"
        [routerLinkActiveOptions]="{exact: false}">{{ 'translate_plans' | translate }}</a>
      <a [routerLink]="['', 'contact-us']" routerLinkActive="text-primary-500 font-bold"
        [routerLinkActiveOptions]="{exact: false}">{{ 'translate_contact_us' | translate }}</a>
      <div ngbDropdown #languageMenu="ngbDropdown" placement="bottom-end" class="inline-block">
        <div ngbDropdownToggle class="border-[1px] border-gray-200 py-1 px-2 rounded cursor-pointer mx-3 hover:bg-gray-100 after:hidden"
          [ngClass]="{'bg-gray-100': languageMenu.isOpen()}">
          <i class="fa-solid fa-globe me-2"></i>
          {{ 'translate_' + translateService.currentLang | translate }}
        </div>
        <div ngbDropdownMenu
          class="mt-2 ltr:right-0 rtl:left-0 border-gray-100 bg-white shadow min-w-fit w-52 text-sm [&_button]:text-start [&_button]:block [&_button]:w-full [&_button]:px-6 [&_button]:py-2 [&_button:hover]:bg-gray-100">
          <button (click)="changeLanguage('ar'); languageMenu.close()" [disabled]="translateService.currentLang === 'ar'"
            [ngClass]="{'text-gray-400': translateService.currentLang === 'ar'}">
            {{ 'translate_arabic_in_arabic' | translate }}
          </button>
          <button (click)="changeLanguage('en'); languageMenu.close()" [disabled]="translateService.currentLang === 'en'"
            [ngClass]="{'text-gray-400': translateService.currentLang === 'en'}">
            {{ 'translate_english_in_english' | translate }}
          </button>
        </div>
      </div>
    </div>
    <div ngbDropdown placement="bottom-end" class="text-black">
      <div ngbDropdownToggle
        class="flex align-items-center h-10 bg-gray-200 rounded-full pe-[1.25rem] cursor-pointer after:hidden">
        <img  [src]="shared.userIdentity?.image ?? 'assets/img/user-profile.jpg'" class="h-full rounded-full" />
        <div>
          <span class="mx-2">{{ 'translate_hi' | translate }},</span>

          <span>{{shared.userIdentity?.firstName}}</span>
        </div>
        <i class="fa-solid fa-angle-down ms-2"></i>
      </div>
      <div ngbDropdownMenu
        class="mt-2 ltr:right-0 rtl:left-0 border-gray-100 bg-white shadow min-w-fit w-52 text-sm [&_a]:text-start [&_a]:block [&_a]:px-6 [&_a]:py-2 [&_a:hover]:bg-gray-100">
        <a [routerLink]="['']">{{ 'translate_view_profile' | translate }}</a>
        <a [routerLink]="['']">{{ 'translate_about_us' | translate }}</a>
        <a [routerLink]="['']">{{ 'translate_the_branches' | translate }}</a>
        <a [routerLink]="['']">{{ 'translate_terms_and_conditions' | translate }}</a>
        <a [routerLink]="['']">{{ 'translate_return_policy' | translate }}</a>
        <a [routerLink]="['']">{{ 'translate_language' | translate }}</a>
        <a class="specialbar" [href]="glameraURL">{{ 'translate_go_to_glamera' | translate }}</a>
        <a (click)="logOut()" class="text-red-500">
          <i class="fa-solid fa-arrow-right-from-bracket"></i>
          {{ 'translate_log_out' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
<div class="m-auto px-4 pb-6 max-w-[62rem] pt-32">
  <router-outlet></router-outlet>
</div>
<div class="text-gray-400 text-xs text-center p-4">
  {{ 'translate_copy_right_footer' | translate }}
</div>
<div class="fixed bottom-0 ltr:right-0 rtl:left-0 m-2 text-white">
  <button type="button" class="block mb-2 h-12 w-12 rounded-full bg-teal-500 hover:bg-teal-600">
    <i class="fa-brands fa-whatsapp fa-fw fa-lg"></i>
  </button>
  <button type="button" class="block h-12 w-12 rounded-full bg-primary-500 hover:bg-primary-600">
    <i class="fa-regular fa-comment-dots"></i>
  </button>
</div>


 <app-dialog   #sideDialog ></app-dialog>
